<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      리뷰하기<router-link to="/mypage/my_info"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_14">
      <div class="sect_txt">리뷰할 카테고리를 선택해주세요.</div>
      <div class="sect_cate">
        <div
          v-for="menu in mainMenuList"
          :key="menu.seq"
          :class="{ on: checkOn(menu.use_yn) }"
        >
          <router-link to="" @click.native="goPage(menu.seq)">{{
            menu.title
          }}</router-link>
        </div>
      </div>
    </div>
    <!---->
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  created() {
    //초기화
    this.$store.dispatch("common/setFooter", {
      mypage: true,
    });
    //초기화
    this.getMainMenu();
  },
  computed: {
    ...mapState("review", ["result", "msg", "mainMenuList"]),
    ...mapState("mypage", ["checkAuth", "myInfo"]),
  },
  methods: {
    async getMainMenu() {
      await this.$store.dispatch("review/getMainMenu");
    },
    checkOn(use) {
      if (use === "Y") {
        return true;
      }
      return false;
    },
    goPage(seq) {
      if (/*상품*/ seq === "1") this.$router.push("/market_main_list");
      if (/*뉴스 */ seq === "3") this.$router.push("/review/review_choice");
    },
  },
};
</script>

<style></style>
